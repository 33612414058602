import {
  LandingPage,
  BlockProductShowcase,
  BlockBrandlead,
  BlockDirectusTypeCarousel,
  BlockDirectusTypeCallToAction,
} from '&/types';

type blocks =
  | BlockBrandlead
  | BlockDirectusTypeCarousel
  | BlockDirectusTypeCallToAction
  | BlockProductShowcase
  | BlockBrandlead;

export default function useDirectusBlock<B extends blocks>(
  landing: LandingPage,
  name: string,
  collection: string,
): Omit<B, 'status'> {
  return landing?.blocks?.find(
    (block) => block.collection === collection && block.name === name,
  ) as Omit<B, 'status'>;
}
