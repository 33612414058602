/** Based on Hotjar's implementation
 * @see https://github.com/hotjar/hotjar-js/blob/main/src/index.ts
*/
import { checkReadyState, initScript, executeFacebookPixelCommand } from './utils';

const FacebookPixel = {
  init: (pixelId: string): boolean => {
    try {
      initScript();
      executeFacebookPixelCommand('init', pixelId);
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  },
  track: (tracked = 'PageView'): boolean => {
    try {
      executeFacebookPixelCommand('track', tracked);
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  },
  isReady: checkReadyState,
};

export default FacebookPixel;
