import { InjectionKey } from 'vue';
import {
  createStore, createLogger, Store, useStore as baseUseStore,
} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { RootState } from './types';
import portalModule from './portal';
import directusModule from './directus';
import repeatableActionModule from './repeatableAction';

export const key: InjectionKey<Store<RootState>> = Symbol('Main Vuex Store InjectionKey');

function initial(): RootState {
  return {
    location: null,
    landingPage: null,
    repeatableActionId: null,
    scopes: [],
    bltToken: null,
    siteTitle: null,
    siteFavicon: null,
  };
}

export default createStore<RootState>({
  state: initial,
  mutations: {
    RESET(state) {
      const s: RootState = initial();
      Object.keys(s).forEach((k: string) => {
        state[k] = s[k];
      });
    },
    SET_LOCATION(state, location) {
      state.location = location;
    },
    SET_LANDINGPAGE(state, landingPage) {
      state.landingPage = landingPage;
    },
    SET_REPEATABLE_ACTION_ID(state, repeatableAction) {
      state.repeatableActionId = repeatableAction;
    },
    SET_SCOPES(state, scopes) {
      state.scopes = scopes;
    },
    SET_BLT_TOKEN(state, token) {
      state.bltToken = token;
    },
  },
  actions: {
    RESET({ commit }) {
      commit('RESET');
      commit('directus/RESET');
      commit('portal/RESET');
    },
  },
  getters: {
    isSameState:
      (state) => (
        location: string | null,
        page: string | null,
        action: string | null,
        scopes: string[] | null,
      ): boolean => {
        const stateOfLocation = state.location === location;
        const stateOfLandingPage = state.landingPage === page;
        const stateOfAction = state.repeatableActionId === action;
        const stateOfScopesLength = state.scopes.length === (scopes?.length ?? 0);
        const stateOfScopesIncludes = state.scopes.every((scope) => scopes?.includes(scope));

        if (process.env.NODE_ENV === 'development') {
          console.log(
            'Same State: '
            + `\n\tState of Location: ${stateOfLocation ? 'Yes' : 'No'}`
            + `\n\tState of Landing Page: ${stateOfLandingPage ? 'Yes' : 'No'}`
            + `\n\tState of Action: ${stateOfAction ? 'Yes' : 'No'}`
            + `\n\tState of Scopes:length: ${stateOfScopesLength ? 'Yes' : 'No'}`
            + `\n\tState of Scopes:includes: ${stateOfScopesIncludes ? 'Yes' : 'No'}`,
          );
        }

        return stateOfLocation
          && stateOfLandingPage
          && stateOfAction
          && stateOfScopesLength
          && stateOfScopesIncludes;
      },
    getLocation: (state) => state.location,
    getLandingPage: (state) => state.landingPage,
  },
  modules: {
    portal: portalModule,
    directus: directusModule,
    repeatableAction: repeatableActionModule,
  },
  plugins: [
    createLogger(),
    createPersistedState({
      storage: sessionStorage,
    }),
  ],
});

// define your own `useStore` composition function
export function useStore(): Store<RootState> {
  return baseUseStore(key);
}
