import { App } from 'vue';
import {
  VDirectusImage,
  VVideoPlayer,
  VSwitchCase,
} from '~/components';

export default function useComponents(app: App<Element>): void {
  app.component('v-switch-case', VSwitchCase);
  app.component('v-directus-img', VDirectusImage);
  app.component('v-video-player', VVideoPlayer);
}
