import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosRequestHeaders,
} from 'axios';

// eslint-disable-next-line no-shadow
enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: AxiosRequestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
};

export class Client {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp(): AxiosInstance {
    const http = axios.create({
      baseURL: process.env.VUE_APP_PROXY_SERVER_HOST ?? `//${window.location.hostname}:${process.env.VUE_APP_PROXY_SERVER_PORT ?? 3000}`,
      headers,
      withCredentials: true,
    });

    http.interceptors.response.use(
      (response) => response,
      (error): void => {
        const { response } = error;
        return Client.handleError(response);
      },
    );

    this.instance = http;
    return http;
  }

  request<T, R = T>(config: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.http.request<T, AxiosResponse<R>>(config);
  }

  get<T, R = T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.http.get<T, AxiosResponse<R>>(url, config);
  }

  post<T, R = T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.http.post<T, AxiosResponse<R>>(url, data, config);
  }

  put<T, R = T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.http.put<T, AxiosResponse<R>>(url, data, config);
  }

  delete<T, R = T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.http.delete<T, AxiosResponse<R>>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private static handleError(response: AxiosResponse): void {
    const { status } = response;

    switch (status) {
      case StatusCode.InternalServerError:
        // Handle InternalServerError
        break;

      case StatusCode.Forbidden:
        // Handle Forbidden
        break;

      case StatusCode.Unauthorized:
        // Handle Unauthorized
        break;

      case StatusCode.TooManyRequests:
        // Handle TooManyRequests
        break;

      default:
        Promise.reject(response);
        break;
    }
  }
}

const client = new Client();

export default client;
