import { Module } from 'vuex';
import { RepeatableActionState, RootState } from './types';

function initial(): RepeatableActionState {
  return {
    action: null,
  };
}

const module: Module<RepeatableActionState, RootState> = {
  namespaced: true,
  state: initial,
  mutations: {
    RESET(state) {
      const s: RepeatableActionState = initial();
      Object.keys(s).forEach((k: string) => {
        state[k] = s[k];
      });
    },
    SET_REPEATABLE_ACTION(state, repeatableAction) {
      state.action = repeatableAction;
    },
  },
  getters: {
    hasRepeatableAction: (state) => state.action !== null,
    getRepeatableAction: (state) => state.action,
  },
};

export default module;
