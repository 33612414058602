import client, { Client } from './client';
import { ApiModule as BaseApiModule, TypeMap } from './types';
import SessionApiModule from './session';
import PortalApiModule from './portal';
import EventApiModule from '~/api/events';

export type ApiClient = Client;

export type ApiModule = BaseApiModule<ApiClient>;

type ApiModules<C extends ApiClient> = {
  session: SessionApiModule<C>;
  portal: PortalApiModule<C>;
  events: EventApiModule<C>;
};

export class Api<T extends TypeMap<ApiModule>> {
  map: Map<keyof T, ApiModule> = new Map();

  use<M extends keyof T>(module: M): T[M] {
    return this.map.get(module) as T[M];
  }

  register<M extends keyof T, I extends ApiModule>(module: M, instance: I): this {
    this.map.set(module, instance);

    return this;
  }
}

export default new Api<ApiModules<ApiClient>>()
  .register('session', new SessionApiModule(client))
  .register('portal', new PortalApiModule(client))
  .register('events', new EventApiModule(client));
