import FacebookPixel from './facebook-pixel';

export default function useFacebookPixel(
  id: string,
  tracked = 'PageView',
  useOnlyInProduction = false,
): void {
  if ((useOnlyInProduction && process.env.NODE_ENV === 'production') || !useOnlyInProduction) {
    FacebookPixel.init(id);
    FacebookPixel.track(tracked);

    console.log(
      '%c📔 Facebook Pixel Tracking Enabled 📔%c',
      'color: #fff; background: #529a0e; padding: 1px; border-radius: 3px 0 0 3px;',
      'background:transparent',
    );
  } else {
    console.log(
      '%c📔 Facebook Pixel Disabled 📔%c',
      'color: #fff; background: #ad0000; padding: 1px; border-radius: 3px 0 0 3px;',
      'background:transparent',
    );
  }
}
