import { Client } from './client';
import { ApiModule } from './types';
import { sessionEnded } from '&/constants';
import { NewSession as NewSessionRequest } from '&/requests';
import { NewSession as NewSessionResponse } from '&/responses';
import { useStore } from '~/store';

export default class SessionApiModule<C extends Client> implements ApiModule<C> {
  client: C;

  public constructor(client: C) {
    this.client = client;
  }

  public async new(
    location: string,
    landingPage: string,
    identifier: string,
    bltToken: string,
    scopes: string[],
  ): Promise<NewSessionResponse> {
    const store = useStore();

    if (
      // eslint-disable-next-line dot-notation
      store.getters['isSameState'](location, landingPage, identifier, scopes)
      && store.getters['directus/hasLandingPage']
    ) {
      return {
        page: store.getters['directus/getLandingPage'],
        action: store.getters['repeatableAction/getRepeatableAction'],
      };
    }

    store.commit('SET_LOCATION', location);
    store.commit('SET_LANDINGPAGE', landingPage);
    store.commit('SET_REPEATABLE_ACTION_ID', identifier);
    store.commit('SET_SCOPES', scopes);
    store.commit('SET_BLT_TOKEN', bltToken);

    store.commit('directus/RESET');
    store.commit('portal/RESET');

    return this.client
      .post<NewSessionRequest, NewSessionResponse>('/session/new', {
        location,
        landingPage,
        bltToken,
        repeatableAction: identifier,
        scopes,
      })
      .then((response) => {
        const { data } = response;

        store.commit('directus/SET_PAGE', data.page);
        store.commit('repeatableAction/SET_REPEATABLE_ACTION', data.action);

        return data;
      });
  }

  public async end(): Promise<boolean> {
    return this.client.delete<null, string>('/session/end').then((response) => {
      if (response.data !== sessionEnded) {
        return false;
      }

      const store = useStore();

      store.commit('RESET');

      return true;
    });
  }
}
