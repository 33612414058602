import { Module } from 'vuex';
import { DirectusState, RootState } from './types';
import { LandingPage } from '&/types';

function initial(): DirectusState {
  return {
    page: null,
  };
}

const module: Module<DirectusState, RootState> = {
  namespaced: true,
  state: initial,
  mutations: {
    RESET(state) {
      const s: DirectusState = initial();
      Object.keys(s).forEach((k: string) => {
        state[k] = s[k];
      });
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
  },
  getters: {
    hasLandingPage: (state): boolean => state.page !== null,
    getLandingPage: (state): LandingPage|null => state.page,
  },
};

export default module;
