
import {
  SetupContext, h, toRef, defineComponent, PropType,
} from 'vue';

export default defineComponent({
  props: {
    value: {
      type: [String, Number] as PropType<string | number | undefined>,
      default: undefined,
    },
  },
  setup(props, context: SetupContext) {
    const switchValue = toRef(props, 'value');

    const slotFn = switchValue.value && switchValue.value in context.slots
      ? context.slots[switchValue.value]
      : context.slots.default;

    return slotFn ? () => slotFn(context.attrs) : () => h('div', {}, ['']);
  },
});
