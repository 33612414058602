export const landingPageKeyPrefix = 'landing-pages:';
export const LocationKeyPrefix = 'location:';
export const LocationPrefillKeyPrefix = 'location:';
export const feedItemsKeyPrefix = 'feed-item:';
export const authToken = 'auth-token';
export const actionPrefix = 'repeatable-action:';
export const sessionEnded = 'session-ended';
export const locationKeysPrefix = 'location-keys:';
export const landingPagesKeysPrefix = 'landing-pages-keys:';
export const actionKeysPrefix = 'action-keys:';
