/** Based on Hotjar's implementation
 * @see https://github.com/hotjar/hotjar-js/blob/main/src/utils.ts
 */
type FacebookPixelCommand = 'init' | 'track';

export interface WindowWithFacebookPixel extends Window {
  fbq?: (method: FacebookPixelCommand, ...data: unknown[]) => void;
}

declare const window: WindowWithFacebookPixel;

const hasWindow = () => typeof window !== 'undefined';

export const checkReadyState = (): boolean => {
  if (hasWindow() && window.fbq) {
    return true;
  }

  return false;
};

export const executeFacebookPixelCommand = (
  command: FacebookPixelCommand,
  ...args: unknown[]
): void => {
  if (hasWindow() && window.fbq) {
    return window.fbq(command, ...args);
  }

  throw Error('Facebook Pixel is not available, make sure init has been called.');
};

const appendScript = (scriptText: string, scriptId: string): boolean => {
  try {
    const existingScript = document.getElementById(scriptId) as HTMLScriptElement;
    const script = existingScript || document.createElement('script');
    script.id = scriptId;
    script.innerText = scriptText;
    script.crossOrigin = 'anonymous';

    document.head.appendChild(script);
    return true;
  } catch {
    return false;
  }
};

export const initScript = (): void => {
  const hotjarScriptCode = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');";
  const isAppended = appendScript(hotjarScriptCode, 'facebook-pixel-init-script');
  if (isAppended && checkReadyState()) {
    return;
  }
  throw Error('Failed to initialize Facebook Pixel init script.');
};
