import { setOptions, bootstrap } from 'vue-gtag';

export interface GoogleTagsOptions {
  id: string;
}

export default async function useGoogleTags(
  options: GoogleTagsOptions,
): Promise<Gtag.Gtag | boolean> {
  setOptions({
    config: {
      id: options.id,
    },
  });

  try {
    const tag = await bootstrap();

    console.log(
      '%c👁️‍🗨️ Google Tag Tracking Enabled 👁️‍🗨️%c',
      'color: #fff; background: #529a0e; padding: 1px; border-radius: 3px 0 0 3px;',
      'background:transparent',
    );

    return tag as Gtag.Gtag;
  } catch (error) {
    console.log(
      '%c👁️‍🗨️ Google Tag Tracking Failed 👁️‍🗨️%c',
      'color: #fff; background: #ad0000; padding: 1px; border-radius: 3px 0 0 3px;',
      'background:transparent',
    );

    return false;
  }
}
