import { Client } from './client';
import { ApiModule } from './types';
import { EventTracking } from '&/requests';

export default class EventApiModule<C extends Client> implements ApiModule<C> {
  client: C;

  public constructor(client: C) {
    this.client = client;
  }

  public async track(
    event: string,
    eventData?: unknown,
  ): Promise<string> {
    return this.client
      .post<EventTracking, string>('/events', {
        event,
        data: eventData,
      })
      .then((response) => {
        const { data } = response;

        return data;
      });
  }
}
