import Hotjar from '@hotjar/browser';
import { useRouter } from 'vue-router';

export default function useHotjar(
  id: string,
  snippetVersion = 6,
  useOnlyInProduction = false,
): void {
  if ((useOnlyInProduction && process.env.NODE_ENV === 'production') || !useOnlyInProduction) {
    Hotjar.init(Number(id), snippetVersion);

    const router = useRouter();

    router.afterEach((to, from, failure) => {
      if (!failure) {
        Hotjar.stateChange(to.fullPath);
      }
    });
    console.log(
      '%c🔥 HotJar Tracking Enabled 🔥%c',
      'color: #fff; background: #529a0e; padding: 1px; border-radius: 3px 0 0 3px;',
      'background:transparent',
    );
  } else {
    console.log(
      '%c🔥 HotJar Tracking Disabled 🔥%c',
      'color: #fff; background: #ad0000; padding: 1px; border-radius: 3px 0 0 3px;',
      'background:transparent',
    );
  }
}
