// Styles
import '@mdi/font/css/materialdesignicons.css';
// eslint-disable-next-line import/extensions
import 'vuetify/styles';
// Translations provided by Vuetify
import { nl, en } from 'vuetify/locale';

// Mdi icons
import { aliases, mdi } from 'vuetify/iconsets/mdi';

// Vuetify
import { createVuetify } from 'vuetify';

export default createVuetify({
  locale: {
    defaultLocale: 'nl',
    fallbackLocale: 'en',
    messages: { nl, en },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
