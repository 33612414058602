<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style lang="css">
html,
body {
  overflow: auto !important;
}

.v-card-text {
  opacity: inherit !important;
}
</style>
