import { Module } from 'vuex';
import { ParseableTexts } from '&/types';
import { PortalState, RootState } from './types';

function initial(): PortalState {
  return {
    location: null,
    texts: null,
  };
}

const module: Module<PortalState, RootState> = {
  namespaced: true,
  state: initial,
  mutations: {
    RESET(state) {
      const s: PortalState = initial();
      Object.keys(s).forEach((k: string) => {
        state[k] = s[k];
      });
    },
    SET_LOCATION(state, location) {
      state.location = location;
    },
    SET_TEXTS(state, texts) {
      state.texts = texts;
    },
  },
  getters: {
    hasLocation: (state) => state.location !== null,
    getLocation: (state) => state.location,
    hasTexts: (state) => state.texts !== null,
    hasSameTexts: (state) => (texts: ParseableTexts | null) => {
      const keysA = Object.keys(state.texts ?? {});
      const keysB = Object.keys(texts ?? {});

      return keysA.every((key) => keysB.includes(key));
    },
    getTexts: (state) => state.texts,
  },
};

export default module;
