function isMetaElement(element: Element): element is HTMLMetaElement {
  return element.tagName === 'META';
}

function isTitleElement(element: Element): element is HTMLTitleElement {
  return element.tagName === 'TITLE';
}

function handleMetaElement(element: HTMLMetaElement, content: string): void {
  if (element.getAttribute('content') !== content) {
    element.setAttribute('content', content);
  }
}

function handleTitleElement(element: HTMLTitleElement, content: string): void {
  if (element.text !== content) {
    // eslint-disable-next-line no-param-reassign
    element.text = content;
  }
}

function handleElement(element: Element, content: string): void {
  if (isTitleElement(element)) {
    handleTitleElement(element, content);
  } else if (isMetaElement(element)) {
    handleMetaElement(element, content);
  }
}

export function useTitle(title: string): void {
  document.title = title;

  const nodes = document.querySelectorAll("title, meta[name*='title']");
  nodes.forEach((element) => handleElement(element, title));
}

export function useFavicon(href: string): void {
  const existingFavicon = document.getElementById('favicon') as HTMLLinkElement;
  const favicon = existingFavicon ?? document.createElement('link') as HTMLLinkElement;
  favicon.id = 'favicon';
  favicon.rel = 'icon';
  favicon.href = href;

  document.head.appendChild(favicon);
}
