import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Index from '../views/Index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/:layout/:landingPage/:location',
    name: 'layout-landing-location',
    props: true,
    component: Index,
  },
  {
    path: '/:layout/:landingPage',
    name: 'layout-landing',
    props: true,
    component: Index,
  },
  {
    path: '/:layout',
    name: 'layout',
    props: true,
    component: Index,
  },
  {
    path: '/:path(.*).*',
    name: 'fallback',
    component: Index,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
