import { useStore } from '~/store';
import { Location, ParseableTexts } from '&/types';
import { Client } from './client';
import { ApiModule } from './types';

export default class PortalApiModule<C extends Client> implements ApiModule<C> {
  client: C;

  private locationLoading = false;

  public constructor(client: C) {
    this.client = client;
  }

  public async location(): Promise<Location> {
    const store = useStore();
    if (!this.locationLoading) {
      this.locationLoading = true;
    } else {
      let tries = 0;
      const maxTries = 20;
      while (tries < maxTries && !store.getters['portal/hasLocation']) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });
        tries += 1;
      }
    }

    if (store.getters['portal/hasLocation']) {
      return store.getters['portal/getLocation'];
    }

    return this.client.get<null, Location>('/portal')
      .then((response) => {
        const { data } = response;

        store.commit('portal/SET_LOCATION', data);

        return data;
      });
  }

  public async parse(texts: ParseableTexts): Promise<ParseableTexts> {
    const store = useStore();

    if (
      store.getters['portal/hasTexts']
      && store.getters['portal/hasSameTexts'](texts)
    ) {
      return store.getters['portal/getTexts'];
    }

    return this.client.post<{ texts: ParseableTexts }, ParseableTexts>('/portal/actions/parse', {
      texts,
    })
      .then((response) => {
        const { data } = response;

        store.commit('portal/SET_TEXTS', data);

        return data;
      });
  }
}
