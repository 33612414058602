import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store, { key } from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { useComponents } from './composables';

loadFonts();

const app = createApp(App);

useComponents(app);

app.use(router);
app.use(store, key);
app.use(vuetify);

app.use(VueGtag, {
  bootstrap: false,
}, router);

app.mount('#app');
