import { ID } from '&/types';

// eslint-disable-next-line no-shadow
export enum ImageFit {
  COVER = 'cover',
  CONTAIN = 'contain',
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

export default function useDirectusImage(
  id: ID,
  width: number,
  height: number,
  fit: ImageFit,
  quality: number,
  format: string | null,
  withoutEnlargement: false,
): URL {
  const host = process.env.VUE_APP_DIRECTUS_HOST as string;
  const url = new URL(`${host}/assets/${id}`);
  const params = url.searchParams;

  params.append('width', Math.round(width).toString());
  params.append('height', Math.round(height).toString());
  params.append('quality', Math.round(quality).toString());
  params.append('fit', fit);
  params.append('withoutEnlargement', (withoutEnlargement ? 1 : 0).toString());

  if (format) {
    params.append('format', format);
  }

  return url;
}

export function useSizes(sizes: number[], width: number): string {
  return Array.from(sizes)
    .filter((size) => size < width)
    .sort((a, b) => a - b)
    .map((size) => `( max-width: ${size}px) ${size}px`)
    .concat(`${width}px`)
    .join(', ');
}

export function resolveToSourceSetString(
  id: ID,
  width: number,
  height: number,
  original = false,
  fit: ImageFit = ImageFit.COVER,
  format: string | null = null,
): string {
  const image = useDirectusImage(id, width, height, fit, 100, format, false).toString();

  return original ? `${image}` : `${image} ${width}w`;
}

export function useSourceSets(
  id: ID,
  sizes: number[],
  width: number,
  height: number,
  fit: ImageFit = ImageFit.COVER,
  format: string | null = null,
): string {
  return Array.from(sizes)
    .filter((size) => size < width)
    .sort((a, b) => a - b)
    .map((size) => resolveToSourceSetString(id, size, height, false, fit, format))
    .concat(resolveToSourceSetString(id, width, height, true, fit, format))
    .join(', ');
}
